@use '@angular/material' as mat;
@include mat.core();

$app-primary: (
  50: #e0edf0,
  100: #b3d2da,
  200: #80b4c1,
  300: #4d96a8,
  400: #268096,
  500: #006983,
  600: #00617b,
  700: #005670,
  800: #004c66,
  900: #003b53,
  A100: #85d3ff,
  A200: #52c1ff,
  A400: #1fafff,
  A700: #06a6ff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$app-accent: (
  50: #f4f7e6,
  100: #e3ecbf,
  200: #d1df95,
  300: #bfd26b,
  400: #b1c94b,
  500: #a3bf2b,
  600: #9bb926,
  700: #91b120,
  800: #88a91a,
  900: #779b10,
  A100: #f0ffcc,
  A200: #e2ff99,
  A400: #d3ff66,
  A700: #ccff4d,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #000000,
    900: #000000,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$app-error: (
  50: #f9eaea,
  100: #f1b3b3,
  200: #e78080,
  300: #dd4d4d,
  400: #d62626,
  500: #cf0000,
  600: #ca0000,
  700: #c30000,
  800: #bd0000,
  900: #b20000,
  A100: #ffdbdb,
  A200: #ffa8a8,
  A400: #ff7575,
  A700: #ff5c5c,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

:root {
  --background: white;
  --text-on-surface-6: #f0f0f0;
  --text-on-surface-20: #cccccc;
  --text-on-surface-67: rgba(0, 0, 0, 0.67);
  --text-on-surface-87: rgba(0, 0, 0, 0.87);
  --accent-contrast-500: white;
  --primary-contrast-500: white;

  // Chat
  --user-message-border: #{mat.get-color-from-palette($app-accent, 800)};
  --agent-message-border: #{mat.get-color-from-palette($app-accent, 500)};
  --notification-background: #{mat.get-color-from-palette($app-primary, 200)};

  // Warning
  --warning-main: #fbb51a;
  --warning-main-background: #fbf7ee;

  // Success
  --success-main: #00af56;

  // OrderStatus
  --status-pending: var(--warning-main);
  --status-pending-background: var(--warning-main-background);
  --status-delivered: #6ab2ff;
  --status-delivered-background: #f2f9ff;
  --status-canceled: #cf0000;
  --status-canceled-background: #faeded;
  --status-text-color: white;

  //WarningItem
  --warning-item-color: #{mat.get-color-from-palette($app-accent, 400)};
}

// mandatory stuff for theming
$app-palette-primary: mat.define-palette($app-primary);
$app-palette-accent: mat.define-palette($app-accent);
$app-palette-error: mat.define-palette($app-error);

// include the custom theme components into a theme object
$app-theme: mat.define-light-theme($app-palette-primary, $app-palette-accent, $app-palette-error);

// include the custom theme object into the angular material theme
@include mat.all-component-themes($app-theme);
