@use '@angular/material' as mat;
@import 'theme';

// Be sure that you only ever include 'mat-core' mixin once!
// it should not be included for each theme.

// theme with: http://mcg.mbitson.com/

.new-chat-dialog {
  position: absolute !important;
  max-width: unset !important;
  top: 0px;
  right: 0px;
  left: 0px;
  bottom: 0px;
  mat-dialog-container {
    padding: unset;
    background-image: url('/assets/customer-assets/background.svg');
    background-size: 300%;
    overflow: auto;
  }
}

.no-padding-dialog .mat-dialog-container {
  padding: 0;
  border: mat.get-color-from-palette($app-primary, 400);
  border-radius: 15px;
  max-width: 690px;
  margin: auto;
}

@media only screen and (min-width: 700px) {
  .new-chat-dialog {
    position: absolute !important;
    top: 20px;
    bottom: 20px;
    right: calc(50% - calc(700px / 2));
    left: calc(50% - calc(700px / 2));
    mat-dialog-container {
      border: 1px solid #c1c7db;
      box-shadow: 0px 3px 6px 0px rgba(131, 146, 165, 0.44);
      border-radius: 10px;
    }
  }
}
.snackbar {
  margin-bottom: 63px !important;
  font-family: 'alberta-mada-medium';
}
